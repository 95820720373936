import * as React from "react";

import Grid from "@mui/material/Grid";
import "../../layout.css";

export default function PageHero(props) {
  return (
    <Grid container className="page-hero">
      <Grid
        item
        xs={12}
        sx={{
          fontFamily: "marvin-round",
          color: "#4d4d4d",
          textAlign: "center",
          position: "relative",
          top: {xs: "10%", sm: "20%"},
        }}
      >
        <h1>{props.children}</h1>
      </Grid>
    </Grid>
  );
}
