import { Grid } from "@mui/material";
import React from "react";
import PageHeaderBlack from "../components/typography/PageHeaderBlack";

import HomeHeroLg from "../components/heroes/HomePageHeroLg";
import HomeHeroSm from "../components/heroes/HomePageHeroSm";
import FamilyImage from "../images/homepage-img-1.png";
import Para from "../components/typography/Para";

const ListItem = {
  fontFamily: "Arial",
  color: "#000",
  fontSize: "1rem",
};

const ResponsiveImg = {
  width: "100%",
  height: "auto",
};

const PageHeader = {
  fontFamily: "brandon-grotesque",
  fontWeight: 700,
  fontStyle: "normal",
  fontSize: "1.7rem",
  textAlign: "left",
  color: "#000",
  display: "inline-flex",
  lineHeight: "2rem",
  margin: 0,
};

export default function HomePage() {
  return (
    <Grid
      container
      sx={{ maxWidth: "1200px", m: "auto", backgroundColor: "#ffc423" }}
    >
      <Grid container sx={{ display: { xs: "none", md: "block" } }}>
        <HomeHeroLg />
      </Grid>
      <Grid container sx={{ display: { xs: "block", md: "none" } }}>
        <HomeHeroSm />
      </Grid>
      <Grid
        container
        sx={{
          maxWidth: { xs: "85%", sm: "80%" },
          m: "auto",
          paddingTop: "3rem",
          paddingBottom: "3rem",
        }}
      >
        <Grid item xs={12} sx={{ textAlign: "left" }}>
          <PageHeaderBlack>
            Thank you for being a part of this study! As a participant in the
          </PageHeaderBlack>
          <PageHeaderBlack>
            PERISCOPE Extension study, you have access to this website.
          </PageHeaderBlack>
          <br />
          <br />
        </Grid>
        <Grid item xs={12} sm={8} sx={{ textAlign: "left" }}>
          <Para>On this site, you’ll find:</Para>
          <ul>
            <li style={ListItem}>Your study responsibilities</li>
            <li style={ListItem}>
              Details about the health exams and tests at your study clinic
              visits
            </li>
            <li style={ListItem}>A schedule of your study clinic visits</li>
          </ul>
          <Para>
            You can also take a break from what’s going on in the study and
            visit the Game Center where you can play a game. You can play as
            often as you like and try to set your personal high score each time.
            You can learn more about this on the Game Center page.
          </Para>
          <br />
          <Para>
            Whether you want to check out what is happening in the study or
            you’re ready to earn more gaming points, be sure to visit this site
            often!
          </Para>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ padding: { xs: "3rem", sm: "1rem" } }}>
          <img style={ResponsiveImg} alt="Family" src={FamilyImage} />
        </Grid>
      </Grid>
    </Grid>
  );
}
