import * as React from "react";

import Grid from "@mui/material/Grid";
import "../../layout.css";

export default function HomeHeroLg() {
  return (
    <Grid container className="home-page-hero-lg">
      <Grid
        item
        xs={12}
        sx={{
          fontFamily: "marvin-round",
          fontSize: "1.2rem",
          color: "#4d4d4d",
          textAlign: "center",
          position: "relative",
          top: "50%",
        }}
      >
        <h1 style={{ margin: "0px" }}>Welcome to the</h1>
        <h1 style={{ margin: "0px" }}>PERISCOPE Extension</h1>
        <h1 style={{ margin: "0px" }}>Research Study</h1>
      </Grid>
    </Grid>
  );
}
