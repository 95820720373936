import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./typography.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GameCenter from "./routes/GameCenter";
import OperationOcean from "./routes/OperationOcean";
import AdventureSub from "./routes/AdventureSub";
import TreasuresOfTheSeas from "./routes/TreasuresOfTheSeas";
import HealthExams from "./routes/HealthExams";
import HomePage from "./routes/HomePage";
import StudyClinicVisits from "./routes/StudyClinicVisits";
import StudyRepsonsibilities from "./routes/StudyResponsibilities";
import StudyGuideHomePage from "./routes/StudyGuideHomePage";

import { CssBaseline } from "@mui/material";

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<HomePage />} />
            <Route
              path="/study-responsibilities"
              element={<StudyRepsonsibilities />}
            />
            <Route path="/health-exams-and-tests" element={<HealthExams />} />
            <Route
              path="/study-clinic-visits"
              element={<StudyClinicVisits />}
            />
            <Route path="/game-center" element={<GameCenter />} />
            <Route path="/operation-ocean" element={<OperationOcean />} />
            <Route path="/adventure-sub" element={<AdventureSub />} />
            <Route path="/treasures-of-the-seas" element={<TreasuresOfTheSeas />} />
            <Route path="/study-guide" element={<StudyGuideHomePage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </CssBaseline>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
