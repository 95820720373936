import { Grid } from "@mui/material";
import React from "react";
import PageHero from "../components/heroes/PageHero";
import Para from "../components/typography/Para";
import ParaList from "../components/typography/ParaList";

import HospitalIcon from "../images/hospital-icon.png";
import TelephoneIcon from "../images/telephone-icon.png";

const ClinicHeader = {
  fontFamily: "marvin-round",
  fontSize: "1.2rem",
  color: "#4D4D4E",
};

const ResponsiveImg = {
  width: "200px",
  height: "200px",
};

export default function StudyClinicVisits() {
  return (
    <Grid
      container
      sx={{ maxWidth: "1200px", m: "auto", backgroundColor: "#ffc423" }}
    >
      <PageHero>Study Clinic Visits</PageHero>
      <Grid
        container
        sx={{
          maxWidth: { xs: "85%", sm: "70%" },
          m: "auto",
          paddingTop: "1rem",
          paddingBottom: "3rem",
        }}
      >
        <Grid item xs={12}>
          <Para>
            Your study clinic visits will take place about every 4 weeks (1
            month). Some of your visits will be in-person visits at the study
            clinic, and some of the visits will be done over the phone with the
            study doctor/staff.
          </Para>
          <br />
          <Para>
            This page shows you which visits will take place at the study clinic
            and which visits will be phone calls. If you have questions about
            these visits, please ask the study doctor/staff.
          </Para>
          <br />
        </Grid>
        <Grid container sx={{ textAlign: "center" }}>
          <Grid item xs={12} sm={4}>
            <img style={ResponsiveImg} alt="Hospital Icon" src={HospitalIcon} />
            <h2 style={ClinicHeader}>In-Person Visits</h2>
            <ParaList>Visit 1*</ParaList>
            <ParaList>Visit 2 (Week 4)</ParaList>
            <ParaList>Visit 4 (Week 12)</ParaList>
            <ParaList>Visit 7 (Week 24)</ParaList>
            <ParaList>Visit 10 (Week 36)</ParaList>
            <ParaList>Visit 14 (Week 52)</ParaList>
            <br />
          </Grid>
          <Grid item xs={12} sm={8}>
            <img
              style={ResponsiveImg}
              alt="Telephone Icon"
              src={TelephoneIcon}
            />
            <h2 style={ClinicHeader}>Phone Calls</h2>
            <ParaList>Visit 3 (Week 8)</ParaList>
            <ParaList>Visit 5 (Week 16)</ParaList>
            <ParaList>Visit 6 (Week 20)</ParaList>
            <ParaList>Visit 8 (Week 28)</ParaList>
            <ParaList>Visit 9 (Week 32)</ParaList>
            <ParaList>Visit 11 (Week 40)</ParaList>
            <ParaList>Visit 12 (Week 44)</ParaList>
            <ParaList>Visit 13 (Week 48)</ParaList>
            <ParaList>Visit 15 (Week 54)</ParaList>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <br />
          <Para>
            For your in-person visits, don’t forget to bring your eDiary and any
            unused investigational drug.
          </Para>
          <br />
          <Para>
            *This visit will take place on the same day as your last visit in
            the PERISCOPE study.
          </Para>
        </Grid>
      </Grid>
    </Grid>
  );
}
