import * as React from "react";
import { Link } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import PageHero from "../components/heroes/PageHero";
import Para from "../components/typography/Para";
import Iframe from "react-iframe";

const GameCenterHeading = {
  fontFamily: "brandon-grotesque",
  fontWeight: "700",
  fontSize: "1.8rem",
};

// const GameHeading = {
//   fontFamily: "brandon-grotesque",
//   fontWeight: "700",
//   fontSize: "1.5rem",
//   textAlign: "center",
//   margin: "0px",
// };

// const GameLink = {
//   fontFamily: "arial",
//   fontWeight: "700",
//   fontSize: "1rem",
//   textDecoration: "none",
//   color: "#0071CE",
// };

// const ResponsiveImg = {
//   width: "100%",
//   height: "auto",
// };

export default function TreasuresOfTheSeas() {
  return (
    <Grid container sx={{ backgroundColor: "#ffc423" }}>
      <Grid
        item
        sx={{ height: "80vh", width: "100vw" }}
        xs={12}
      >
        <Iframe
          url="https://treasuresoftheseas.private.gamify.com/"
          width="100%"
          height="100%"
          id="myId"
          className="myClassname"
          display="initial"
          position="relative"
          frameBorder={0}
          allowFullScreen={true}
        />
        <a href="/game-center"></a>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Grid
          item
          xs={12}
          sx={{
            fontFamily: "marvin-round",
            textAlign: "center",
          }}
        >
          <Link to="/game-center" style={{ textDecoration: "none", color: "#4d4d4d"}}>
            <h1>
              <ArrowCircleLeftIcon /> Back to Game Center
            </h1>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
}
