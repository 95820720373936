import * as React from "react";

import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

const ParaListItem = styled(Typography)`
  font-family: arial;
  font-size: 1rem;
  color: black;
  margin-bottom: .7rem;
`;

export default function ParaList(props) {
  return <ParaListItem>{props.children}</ParaListItem>;
}
