import { Grid } from "@mui/material";
import React from "react";
import PageHero from "../components/heroes/PageHero";
import Para from "../components/typography/Para";

import StudyDoctorImage from "../images/doctor.png";

const ListItem = {
  fontFamily: "Arial",
  color: "#000",
  fontSize: "1rem",
};

const ResponsiveImg = {
  width: "100%",
  height: "auto",
};

export default function StudyRepsonsibilities() {
  return (
    <Grid
      container
      sx={{ maxWidth: "1200px", m: "auto", backgroundColor: "#ffc423" }}
    >
      <PageHero>Study Responsibilities</PageHero>
      <Grid
        container
        sx={{
          maxWidth: { xs: "85%", sm: "70%" },
          m: "auto",
          paddingTop: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <Grid item xs={12}>
          <Para>
            As a participant in this study, the study doctor/staff need you to
            help them as much as you can. That means doing things they ask you to do, which will help them
            learn more about the investigational drug.
          </Para>
          <br />
          <Para>
            More importantly, it will help keep you in the study. Because if you
            don’t keep up with your study responsibilities, there’s a chance the
            study doctor/staff may remove you from the study.
          </Para>
          <br />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Para>
            Here is a list of things you <strong>should </strong>
            do during the study:
          </Para>
          <ul>
            <li style={ListItem}>
              Come to all study clinic visits (at the clinic and over the phone)
            </li>
            <li style={ListItem}>
              Follow all instructions from the study doctor/staff
            </li>
            <li style={ListItem}>
              Tell the study doctor/staff if you are feeling bad or worse than
              before
            </li>
            <li style={ListItem}>
              Tell the study doctor/staff if you have any changes to your
              medications
            </li>
            <li style={ListItem}>Complete your eDiary as instructed</li>
            <li style={ListItem}>
              Take the investigational drug as instructed
            </li>
          </ul>
          <br />
          <Para>
            Here is a list of things you <strong>should not </strong>
            do during the study:
          </Para>
          <ul>
            <li style={ListItem}>
              Do not take any other medications unless the study doctor/staff
              says it is okay
            </li>
            <li style={ListItem}>
              Do not drink alcohol or take any illicit drugs
            </li>
            <li style={ListItem}>
              Do not participate in any other research studies while you’re in
              this study
            </li>
            <li style={ListItem}>
              Do not give the investigational drug to anyone else
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ padding: { xs: "3rem", sm: "1rem" } }}>
          <img style={ResponsiveImg} alt="Doctor" src={StudyDoctorImage} />
        </Grid>
      </Grid>
    </Grid>
  );
}
