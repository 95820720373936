import { Grid } from "@mui/material";
import React from "react";
import PageHero from "../components/heroes/PageHero";
import Para from "../components/typography/Para";

import Icon1 from "../images/Icon1.png";
import Icon2 from "../images/Icon2.png";
import Icon3 from "../images/Icon3.png";
import Icon4 from "../images/Icon4.png";
import Icon5 from "../images/Icon5.png";
import Icon6 from "../images/Icon6.png";
import Icon7 from "../images/Icon7.png";
import Icon8 from "../images/Icon8.png";
import Icon9 from "../images/Icon9.png";
import Icon10 from "../images/Icon10.png";

import "../layout.css";

const ListHeader = {
  fontFamily: "brandon-grotesque",
  fontWeight: 700,
  fontSize: "21px",
  color: "#1D3767",
  margin: "0px",
};

const ResponsiveImg = {
  width: "75%",
  height: "auto",
};

const IconsAndItems = [
  {
    headerText: "Questions about medications and how you’re feeling",
    bodyText:
      "The study doctor/staff will ask you questions about medications you have taken and are taking right now. They will also ask you how you’re feeling.",
    imgLink: `${Icon1}`,
    imgAlt: "Icon 1",
  },
  {
    headerText: "Review of eDiary",
    bodyText:
      "The study doctor/staff will look at your eDiary entries and may ask you questions about them.",
    imgLink: `${Icon2}`,
    imgAlt: "Icon 2",
  },
  {
    headerText: "Vital signs measurements",
    bodyText:
      "The study doctor/staff will measure your blood pressure, heart rate, respiration rate, body temperature, and body weight. Your height will be measured at Visits 1, 4, 7, 10, and 14.",
    imgLink: `${Icon3}`,
    imgAlt: "Icon 3",
  },
  {
    headerText: "Electrocardiogram (ECG)",
    bodyText:
      "Sticky patches will be placed on your arms, legs, and chest while you are lying down. These patches are connected to a machine that measures the electrical activity of your heart.",
    imgLink: `${Icon4}`,
    imgAlt: "Icon 4",
  },
  {
    headerText: "Physical exam",
    bodyText: "The study doctor/staff will do a general health check.",
    imgLink: `${Icon5}`,
    imgAlt: "Icon 5",
  },
  {
    headerText: "Blood and urine samples",
    bodyText:
      "The study doctor/staff will collect samples of your blood and urine. These samples will be tested to check your health.",
    imgLink: `${Icon6}`,
    imgAlt: "Icon 6",
  },
  {
    headerText: "Questionnaires",
    bodyText:
      "The study doctor/staff will ask you questions about your thoughts and behavior.",
    imgLink: `${Icon7}`,
    imgAlt: "Icon 7",
  },
  {
    headerText: "Physical development check",
    bodyText: "The study doctor/staff will evaluate your physical development.",
    imgLink: `${Icon8}`,
    imgAlt: "Icon 8",
  },
  {
    headerText: "Receive supply of study drug",
    bodyText:
      "The study doctor/staff will give you a supply of your study drug to take at home.",
    imgLink: `${Icon9}`,
    imgAlt: "Icon 9",
  },
  {
    headerText: "Return unused study drug",
    bodyText:
      "You will return any unused study drug to the study doctor/staff. They will also review your use of your study drug.",
    imgLink: `${Icon10}`,
    imgAlt: "Icon 10",
  },
];

export default function HealthExams() {
  return (
    <Grid
      container
      sx={{ maxWidth: "1200px", m: "auto", backgroundColor: "#ffc423" }}
    >
      <PageHero>Health Exams and Tests</PageHero>
      <Grid
        container
        sx={{
          maxWidth: { xs: "85%", sm: "70%" },
          m: "auto",
          paddingTop: "1rem",
          paddingBottom: "3rem",
        }}
      >
        <Grid item xs={12}>
          <Para>
            These are the health exams and tests that will happen at your study
            clinic visits. Not all of these will happen at every study clinic
            visit.
          </Para>
          <br />
          <Para>
            For more details about when these will happen, please look at your
            printed or digital study guide.
          </Para>
          <br />
        </Grid>
        {IconsAndItems.map((a) => {
          return (
            <Grid
              container
              sx={{ marginBottom: { xs: "2rem", sm: "1rem" } }}
              spacing={1}
            >
              <Grid item xs={12} sm={2}>
                <img
                  className="responsive-img"
                  alt={a.imgAlt}
                  src={a.imgLink}
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <h2 style={ListHeader}>{a.headerText}</h2>
                <Para>{a.bodyText}</Para>
              </Grid>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Para>
            Additionally, if you are a female who can get pregnant, you will
            also have regular urine pregnancy tests and menstrual cycle
            assessments.
          </Para>
          <br />
          <Para>
            If you have any questions about these health exams and tests, be
            sure to ask the study doctor/staff or your parents/caregivers.
          </Para>
        </Grid>
      </Grid>
    </Grid>
  );
}
