import * as React from "react";

import Grid from "@mui/material/Grid";
import "../../layout.css";
import HomeHeroArt from "../../images/home-hero-art.png";
import HeroHeader from "../typography/HeroHeader";

const ResponsiveImg = {
  width: "100%",
  height: "auto",
};

export default function HomeHeroSm() {
  return (
    <Grid container className="home-page-hero-sm">
      <Grid item xs={12} sx={{ textAlign: "center", marginBottom: "1rem"}}>
        <HeroHeader>Welcome to the</HeroHeader>
        <HeroHeader>PERISCOPE Extension</HeroHeader>
        <HeroHeader>Research Study</HeroHeader>
      </Grid>
    </Grid>
  );
}
